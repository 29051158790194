import axios from "axios";
import * as React from "react";
import RenderPost from "../components/renderPost";

const PREVIEW_URL = "https://cms.reinventing.co";

const PostPreview = ({ location }) => {
  const [props, setProps] = React.useState({
    preview: true,
    data: { post: {} },
  });

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const slug = params.get("slug");
    const token = params.get("token");
    axios
      .get(
        `${PREVIEW_URL}/ok-carbon-posts?_publicationState=preview&slug=${slug}&token=${token}`
      )
      .then((res) => {
        if (Array.isArray(res.data) && res.data.length > 0) {
          setProps((prev) => ({
            ...prev,
            data: { post: res.data[0] },
          }));
        }
      });
  }, [location]);

  return <RenderPost {...props} />;
};

export default PostPreview;
